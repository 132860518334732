@import "src/styles/mixins";
@import "src/styles/mixins/shadows";

.container {
  --content-message-padding: 8px 0;

  .contentMessage {
    padding: var(--content-message-padding);
    white-space: break-spaces;
  }

  @include xs-screen {
    --content-message-padding: 14px 0;
  }
}

@include xs-screen {
  .root {
    left: 15px;
    right: 15px;
    margin-bottom: 50px;
  }
}

.closeButton path {
  fill: var(--color-white);
  fill-opacity: 1;
}

.infoAlert {
  background-color: var(--color-info-main);
  color: var(--color-white);

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: var(--color-white);
      fill-opacity: 1;
    }
  }

  p {
    color: var(--color-white);
  }
}

.primaryDark {
  background-color: var(--primary-dark);
  color: var(--color-white);

  @include shadow-elevation(3);

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: var(--color-white);
      fill-opacity: 1;
    }
  }

  p {
    color: var(--color-white);
  }
}

.info {
  background-color: var(--color-grey100);

  p {
    color: var(--main-black);
    font-weight: 700;
  }
}

.infoBlue {
  background-color: var(--color-info-main-background);

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: var(--color-info-main);
      fill-opacity: 1;
    }
  }

  p {
    color: var(--main-black);
  }
}

.success {
  background-color: var(--color-success-main);

  svg {
    width: 24px;
    height: 24px;
    color: var(--color-white);

    path {
      fill: var(--color-white);
      fill-opacity: 1;
    }
  }

  p {
    color: var(--color-white);
  }
}

.alert {
  background-color: var(--color-error-main);
  color: var(--color-white);

  p {
    color: var(--color-white);
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.warning {
  background-color: var(--color-warning-main);
  color: var(--color-white);

  p {
    color: var(--color-white);
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.large {
  svg {
    margin: 14px 0;
  }
}

.messageContainer {
  @include lg-screen {
    height: auto;
    min-height: 48px;
  }
}

.message {
  font-size: rem(14px);
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 12px;
  font-weight: 400;

  @include lg-screen {
    font-weight: 700;
  }

  .paragraph {
    font-weight: 500;
    font-size: 16px;
  }
}

@mixin shadow-elevation($elevation: 1) {
  @if $elevation == 1 {
    box-shadow: 0 1px 3px 0 var(--color-elevation1), 0 1px 1px 0 var(--color-elevation2), 0 2px 1px -1px var(--color-elevation3);
  }

  @if $elevation == 2 {
    box-shadow: 0 1px 5px 0 var(--color-elevation1), 0 2px 2px 0 var(--color-elevation2), 0 3px 1px -2px var(--color-elevation3);
  }

  @if $elevation == 3 {
    box-shadow: 0 1px 8px 0 var(--color-elevation1), 0 3px 4px 0 var(--color-elevation2), 0 3px 3px -2px var(--color-elevation3);
  }

  @if $elevation == 4 {
    box-shadow: 0 1px 10px 0 var(--color-elevation1), 0 4px 5px 0 var(--color-elevation2), 0 2px 4px -1px var(--color-elevation3);
  }

  @if $elevation == 5 {
    box-shadow: 0 1px 14px 0 var(--color-elevation1), 0 5px 8px 0 var(--color-elevation2), 0 3px 5px -1px var(--color-elevation3);
  }

  @if $elevation == 6 {
    box-shadow: 0 1px 18px 0 var(--color-elevation1), 0 6px 10px 0 var(--color-elevation2), 0 3px 5px -1px var(--color-elevation3);
  }

  @if $elevation == 7 {
    box-shadow: 0 2px 16px 1px var(--color-elevation1), 0 7px 10px 1px var(--color-elevation2), 0 4px 5px -2px var(--color-elevation3);
  }

  @if $elevation == 8 {
    box-shadow: 0 3px 14px 2px var(--color-elevation1), 0 8px 10px 1px var(--color-elevation2), 0 5px 5px -3px var(--color-elevation3);
  }

  @if $elevation == 9 {
    box-shadow: 0 3px 16px 2px var(--color-elevation1), 0 9px 12px 1px var(--color-elevation2), 0 5px 6px -3px var(--color-elevation3);
  }

  @if $elevation == 10 {
    box-shadow: 0 4px 18px 3px var(--color-elevation1), 0 10px 14px 1px var(--color-elevation2), 0 6px 6px -3px var(--color-elevation3);
  }

  @if $elevation == 11 {
    box-shadow: 0 4px 20px 3px var(--color-elevation1), var(--color-elevation2), 0 6px 7px -4px var(--color-elevation3);
  }

  @if $elevation == 12 {
    box-shadow: 0 5px 22px 4px var(--color-elevation1), 0 12px 17px 2px var(--color-elevation2), 0 7px 8px -4px var(--color-elevation3);
  }

  @if $elevation == 13 {
    box-shadow: 0 5px 24px 4px var(--color-elevation1), 0 13px 19px 2px var(--color-elevation2), 0 7px 8px -4px var(--color-elevation3);
  }

  @if $elevation == 14 {
    box-shadow: 0 5px 26px 4px var(--color-elevation1), 0 14px 21px 2px var(--color-elevation2), 0 7px 9px -4px var(--color-elevation3);
  }

  @if $elevation == 15 {
    box-shadow: 0 6px 28px 5px var(--color-elevation1), 0 15px 22px 2px var(--color-elevation2), 0 8px 9px -5px var(--color-elevation3);
  }

  @if $elevation == 16 {
    box-shadow: 0 6px 30px 5px var(--color-elevation1), 0 16px 24px 2px var(--color-elevation2), 0 8px 10px -5px var(--color-elevation3);
  }

  @if $elevation == 17 {
    box-shadow: 0 6px 32px 5px var(--color-elevation1), 0 17px 26px 2px var(--color-elevation2), 0 8px 11px -5px var(--color-elevation3);
  }

  @if $elevation == 18 {
    box-shadow: 0 7px 34px 6px var(--color-elevation1), 0 18px 28px 2px var(--color-elevation2), 0 9px 11px -5px var(--color-elevation3);
  }

  @if $elevation == 19 {
    box-shadow: 0 7px 36px 6px var(--color-elevation1), 0 19px 29px 2px var(--color-elevation2), 0 9px 12px -6px var(--color-elevation3);
  }

  @if $elevation == 20 {
    box-shadow: 0 8px 38px 7px var(--color-elevation1), 0 20px 31px 3px var(--color-elevation2), 0 10px 13px -6px var(--color-elevation3);
  }

  @if $elevation == 21 {
    box-shadow: 0 8px 40px 7px var(--color-elevation1), 0 21px 33px 3px var(--color-elevation2), 0 10px 13px -6px var(--color-elevation3);
  }

  @if $elevation == 22 {
    box-shadow: 0 8px 42px 7px var(--color-elevation1), 0 22px 35px 3px var(--color-elevation2), 0 10px 14px -6px var(--color-elevation3);
  }

  @if $elevation == 23 {
    box-shadow: 0 9px 44px 8px var(--color-elevation1), 0 23px 36px 3px var(--color-elevation2), 0 11px 14px -7px var(--color-elevation3);
  }

  @if $elevation == 24 {
    box-shadow: 0 9px 46px 8px var(--color-elevation1), 0 24px 38px 3px var(--color-elevation2), 0 11px 15px -7px var(--color-elevation3);
  }
}

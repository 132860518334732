:root {
  --pure-black: #000000;
  --main-black: #252529;
  --main-blue: #1665d8;
  --primary-dark: #14233a;
  --primary-light: #6592ff;
  --color-blue1: #3f6eb514;
  --color-blue2: #003ca6;
  --button-text-color: #ffffff;
  --tile-background-color: #ffffff;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-main-blue-gradient: linear-gradient(128.56deg, #3d83e8 -14.06%, #01398b 124.1%);
  --color-main-black-gradient: linear-gradient(180deg, var(--main-black) -25.84%, rgba(0, 0, 0, 0) 100%);
  --color-grey1: #e5e5e5;
  --color-grey2: #adadad;
  --color-grey3: #3e3f42;
  --color-grey4: #f2f2f2;
  --color-grey5: #e3e3e3;
  --color-grey6: #e2e5ed;
  --color-grey7: #b3b3b3;
  --color-grey8: #cbcbcb;
  --color-grey9: #b5b5b5;
  --color-grey10: #f4f4f4;
  --color-grey11: #6b6c6f;
  --color-grey12: #ebebeb;
  --color-grey13: #9ea0a5;
  --color-grey14: #d4d4d4;
  --color-grey15: #e0e0e0;
  --color-grey16: #7e7e7e;
  --color-grey17: #757575;
  --color-grey18: #e8e8e8;
  --color-grey19: #c4c4c4;
  --color-grey20: #e8eef6;
  --color-grey-background: #fafafa;
  --color-grey-background2: #e5e5e5;
  --color-grey-background-disabled: #e0e0e033;
  --color-grey50: #fafafa;
  --color-grey100: #f5f5f5;
  --color-grey200: #eeeeee;
  --color-grey300: #e0e0e0;
  --color-grey400: #bdbdbd;
  --color-grey500: #9e9e9e;
  --color-grey600: #757575;
  --color-grey700: #616161;
  --color-grey800: #424242;
  --color-grey900: #212121;
  --color-grey700-a: #303030;
  --color-light-grey-background: rgba(0, 0, 0, 0.02);
  --color-types-table-background: rgba(0, 0, 0, 0.01);
  --color-upload-error-tile: #00000012;
  --color-divider: #bcbcbc7f;
  --color-dnd-background: #f0f4f9;
  --color-modal-header: #00000007;
  --color-error-background: rgba(255, 240, 239);
  --color-white-opacity40: #ffffff40;
  --color-white-opacity75: #ffffffbf;
  --color-grey-opacity25: #25252940;
  --color-grey-opacity80: #25252980;
  --color-black-opacity13: #0000000d;
  --color-black-opacity40: #00000040;
  --color-black-opacity66: #00000066;
  --color-black-opacity50: rgba(0, 0, 0, 0.5);
  --color-black-opacity54: rgba(0, 0, 0, 0.54);

  // Note(Andrei): We're going to start using new color namings for all colors.
  // So I think it's better to duplicate these colors for now and then move old colors to the new ones.
  --color-gray-scale25: #fafafa;
  --color-gray-scale50: #eeeeee;
  --color-gray-scale100: #dddddd;
  --color-gray-scale250: #aaaaaa;
  --color-blue-gray25: #f6f8f9;

  // shimmer effect
  --color-grey-shimmer1: rgba(255, 255, 255, 0);
  --color-grey-shimmer2: rgba(255, 255, 255, 0.2);
  --color-grey-shimmer3: rgba(255, 255, 255, 0.5);
  --color-grey-shimmer-bg: #dddddd;

  // colorBlue, removed transparency.
  --color-blue: #3f6eb5;
  --color-blue008: #f0f3f8;
  --color-blue050: #253757;
  --color-light-blue: #d8dce6;

  // Action colors
  --color-action-active: #00000089;
  --color-action-active2: #0000008a;
  --color-action-hover: #0000000a;
  --color-action-hover2: #0000001a;
  --color-action-hover3: #00000073;
  --color-action-selected: #00000014;
  --color-action-disabled: #00000042;
  --color-action-disabled-background: #0000001e;
  --color-action-focus: #0000001e;
  --color-other-outlined-border: #b3b3b3;

  // modal
  --color-backdrop-default: rgba(0, 0, 0, 0.5);
  --color-backdrop-secondary: rgba(0, 0, 0, 0.8);
  --color-modal-backdrop100: #000000b2;
  --color-modal-backdrop200: #000000cc;
  --color-modal-navigation-button-hover: rgba(255, 255, 255, 0.25);

  // button
  --color-button-primary: #1665d8;
  --color-button-primary-hover: #3f6eb5;
  --color-button-primary-hover-background: #3f6eb514;
  --color-info-states-contained-hover-background: linear-gradient(0deg, #0000004d 0%, #0000004d 100%), #2196f3;
  --color-button-primary-disabled: #3f6eb5;
  --color-button-primary-outlined-hover: #f0f4f9;
  --color-button-outlined-hover: #f8f8f8;
  --color-button-navigation: #00000080;
  --color-button-primary-outlined-border: #3f6eb580;
  --color-button-secondary-outlined-border: #f4433680;
  --color-button-error-outlined-hover: #f4433614;
  --color-button-warning-outlined-hover: #ed6c0214;
  --color-warning-states-outlined: #ed6c0280;
  --color-button-secondary-hover: #0000000a;
  --color-button-secondary-disabled: #ffffff80;
  --color-button-error-contained-hover: #ab2f25;
  --color-button-primary-hover-background-default: #2c4d7f;
  --color-button-outlined-hover-background: #dde9f8;

  // notification
  --color-error-main: #f44336;
  --color-success-main: #4caf50;
  --color-success-light: #7bc67e;
  --color-success-alert-background: linear-gradient(0deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%), #4caf50;
  --color-success-dark: #3b873e;
  --color-info-main: #2196f3;
  --color-info-main-background: #e9f5fe;
  --color-info-alert-background: #e9f4fe;
  --color-info-alert-content: #0d3c61;
  --color-warning-main: #ed6c02;

  // text
  --color-text-primary: var(--main-black);
  --color-text-secondary: rgba(0, 0, 0, 0.6);
  --color-text-disabled: #00000061;

  // Elevation colors
  --color-elevation1: #0000001f;
  --color-elevation2: #00000024;
  --color-elevation3: #00000033;

  // Overlay
  --color-overlay-disabled: #ffffffcc;
  --color-backdrop: #00000080;
  --color-drawer: #00000026;
  --color-control-panel-background: #6f6f6f66;
  --color-control-panel-background-active: #6f6f6fd9;

  // Labels
  --color-label-warning: #f19c00;
  --color-label-success: #0ed0aa;
  --color-label-error: #e31b0c;

  // Intents
  --color-intent-replace-shade: #0ce84a14;
  --color-intent-replace-solid: #0ce84a;
  --color-intent-add-shade: #00cfff14;
  --color-intent-add-solid: #00cfff;
  --color-intent-move-shade: #cd23fa14;
  --color-intent-move-solid: #cd23fa;
  --color-intent-remove-solid: $colorActionDisabled;

  // Floor plan
  --color-floor-plan-wall: #2c4d7f;
  --color-floor-plan-wall-thin: #2196f3;
  --color-floor-plan-wall-thin-hover: #ffb400;
  --color-floor-plan-wall-thin-error: #f44336;
  --color-floor-plan-virtual-wall: #f44336;
  --color-floor-plan-wall-confirm: #2c4d7f;
  --color-floor-plan-label-over: #2525290a;

  // Mobile
  --color-blue-dark-gradient: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%), var(--color-blue);

  // Snackbar
  --color-snack-bar-background-dark: #323232;

  // Floor Plan Item
  --color-floor-plan-item-error: #f44336;
  --color-floor-plan-item-graphic-active-border: #2c4d7f;
  --color-floor-plan-item-graphic-active: #ffb400;
  --color-floor-plan-item-graphic-inactive-border: #000000;
  --color-floor-plan-item-graphic-inactive: #f5f5f5;
  --color-ceiling-fan-segment-active: #d9e2f0;
  --color-ceiling-fan-segment-in-active: #e0e0e0;
  --color-ceiling-fan-center-in-active: #b3b3b3;
  --color-ceiling-fan-center-border-in-active: #bdbdbd;
  --color-outlet-rect-active: #2c4d7f;
  --color-outlet-rect-in-active: #bdbdbd;
  --color-outlet-inner-rect-active: #ffffff;
  --color-outlet-inner-rect-in-active: #8c8c8c;
  --color-control-active: #2c4d7f;
  --color-control-in-active: #bdbdbd;
  --color-control-text-active: #ffffff;
  --color-control-text-in-active: #8c8c8c;

  // Table
  --color-table-row: rgba(0, 0, 0, 0.03);

  // Door
  --color-door-hinge-point: #2c4d7f;
  --color-door-hinge-background: #9fb7da;
  --color-door-french-background: #9fb7da;
  --color-door-pivot-wall-background: #2c4d7f;
  --color-door-pivot-direction-background: #9fb7da;
  --color-door-sliding-wall-background: #9fb7da;
  --color-door-folding-door-background: #9fb7da;
  --color-door-intent-rpl-background: #ecfef1;
  --color-door-intent-rpl-border: #0ce84a;
  --color-door-intent-add-background: #ebfbff;
  --color-door-intent-add-border: #00cfff;
  --color-door-intent-mov-background: #fbeeff;
  --color-door-intent-mov-border: #cd23fa;
  --color-door-intent-rem-background: #f5f5f5;
  --color-door-intent-rem-border: #b6b6b6;
  --color-arc-door-background: #3f6eb5;
  --color-arc-door-border: #3f6eb5;
  --color-barn-door-background: #9fb7da;
  --color-pocket-door-background: #9fb7da;

  // Floor Material
  --color-floor-material-tile-border: #6e2594;
  --color-floor-material-tile-background: #f3eef6;
  --color-floor-material-wood-border: #074f57;
  --color-floor-material-wood-background: #ebf1f2;
  --color-floor-material-brick-border: #823038;
  --color-floor-material-brick-background: #f5eeef;
  --color-floor-material-carpet-border: #c9c19f;
  --color-floor-material-carpet-background: #fbfaf7;
  --color-floor-material-vinyl-border: #b4436c;
  --color-floor-material-vinyl-background: #f9f0f3;
  --color-floor-material-concrete-border: #23395b;
  --color-floor-material-concrete-background: #edeff2;
  --color-floor-material-no-material-border: #252529;
  --color-floor-material-no-material-background: #ffffff;
  --color-floor-material-rem-border: #b6b6b6;
  --color-floor-material-rpl-background: #ecfef1;
  --color-floor-material-add-background: #ebfbff;
  --color-floor-material-replaced-background: #f0f3f9;
  --color-floor-material-replaced-border: #1665d8;
  --color-floor-material-brick-grout: #777777;

  // Table Colors
  // Note(Andrei): Juan is working on the Colors Palette using Primitives and Tokens
  // we should move to this approach once they are ready. For making it easier to
  // get started I'm adding them here with duplicate values.
  --color-table-cell-primary-background: #eceff1;
  --color-table-cell-tertiary-background: #f6f8f9;
  --color-table-cell-primary-border: #dddddd;
  --color-table-cell-hover-primary-background: #eeeeee;
  --color-table-cell-hover-secondary-background: #eeeeee;
  --color-table-cell-hover-tertiary-background: #eceff1;
  --nook-logo-bg-color: #14233a;
}
